export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()

  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered)
    return

  const authenticated = await useAuthenticated()

  const { $i18n } = nuxtApp

  const homePath = $i18n.locale.value === $i18n.defaultLocale ? '/' : `/${$i18n.locale.value}`

  const redirectUrl = ref<any>(to?.query?.redirect ?? homePath)

  // if token exists and url is /login redirect to homepage
  if (authenticated.value) {
    abortNavigation()

    if (to?.path === '/login')
      return navigateTo(redirectUrl.value, { external: true })

    return navigateTo(homePath)
  }
})
